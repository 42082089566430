:root{
  --pastel-red: #ffadadff;
  --pastel-orange: #ffd6a5ff;
  --pastel-yellow: #fdffb6ff;
  --pastel-green: #caffbfff;
  --pastel-blue: #9bf6ffff;
  --pastel-navy: #a0c4ffff;
  --pastel-purple: #bdb2ffff;
  --pastel-pink: #ffc6ffff;

  --swiper-pagination-color: #9a0000;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark mode variables go here */
    --pastel-red: #ff6767;
    --pastel-orange: #ffb363;
    --pastel-yellow: #fdff66;
    --pastel-green: #7bfd61;
    --pastel-blue: #6cf4ff;
    --pastel-navy: #619cff;
    --pastel-purple: #806eff;
    --pastel-pink: #ff72ff;
  }
}
