@font-face {
  font-family: 'abeatbyKai';
  src: url('../assets/fonts/abeakrg.ttf');
}

@font-face {
  font-family: 'Alcefun';
  src: url('../assets/fonts/alcefun.otf');
}

:root{
  --primary-font: abeatbyKai;
  --secondary-font: Alcefun;
}

.primary-font-text{
  font-family: var(--primary-font);
}

.secondary-font-text{
  font-family: Alcefun;
}
