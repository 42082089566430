.transfer-badge{
  padding: 10px !important;

  /*right side*/
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;

  /*left side*/
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

}

.transfer-badge ion-text{
  color: #1a1a1a;
}
