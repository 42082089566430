@import "~animate.css";

.error-toast{
  animation: shakeX; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.success-toast{
  animation: bounceInLeft; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.5s; /* don't forget to set a duration! */
}
